import React from 'react'
import { useSelector } from 'react-redux'
import { upload_path } from '../helper/Helper';
import NavItem from './NavItem'

export default function SiderBar() {

  const store = useSelector(state=> state?.common);

  const logout=()=>{
    let token = localStorage?.removeItem("user");
    window.location.reload();
  }

  return (
    <>
  <aside className="main-sidebar sidebar-dark-primary elevation-4">
    <a href={void(0)} className="brand-link">
      {
        store?.siteOptions?.logo ? 
        <img src={ upload_path(store?.siteOptions?.logo) } alt="Admin Logo" className="brand-image"/>
        :
        <span className="brand-text font-weight-light">{(store?.siteOptions?.site_name ? store?.siteOptions?.site_name : "Admin Panel")}</span>
      }
    </a>

    <div className="sidebar">
      <nav className="mt-2">
        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li className="nav-item menu-open">
            <ul className="nav nav-treeview">
                <NavItem name="View Posts" link="/all-posts" />
                <NavItem name="Add New Post" link="/add-new-post" />
                <NavItem name="View Pages" link="/all-pages" />
                <NavItem name="All New Page" link="/add-new-page" />
                <NavItem name="Menu" link="/add-menu" />
                <NavItem name="Settings" link="/settings" />
                <NavItem name="Redirection" link="/redirection" />
                {
                  store?.user && <li className='nav-item' onClick={logout}><a href={void(0)} className="nav-link">Logout</a></li>
                }
            </ul>
          </li>
        </ul>
      </nav>

    </div>

  </aside>
    </>
  )
}
