import React, { useState, useRef, useEffect } from 'react'
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom'
import SiderBar from '../partials/SiderBar';
import { Editor } from '@tinymce/tinymce-react';
import UploadMediaModal from '../widgets/UploadMediaModal';
import { useSelector } from 'react-redux';
import MainLayout from '../layouts/MainLayout';
import axios from 'axios';
import { secure_api, isIterable, urlFromString, upload_path, backend_url } from '../helper/Helper';
import moment from 'moment-mini';
import ErrorMsg from '../widgets/ErrorMsg';
import GalleryFile from '../widgets/GalleryFile';
import GalleryFiles from '../widgets/GalleryFiles';
import Footer from '../partials/Footer';
import TopMenu from '../partials/TopMenu';

export default function Settings(props) {

    const logoRef = useRef(null);
    const faviconRef = useRef(null);
    const siteTitleRef = useRef(null);
    const homePageTitleRef = useRef(null);
    const homePageDescriptionRef = useRef(null);
    const homePageTopContentRef = useRef(null);
    const headTagRef = useRef(null);
    const googleAnalyticsRef = useRef(null);
    const adsensePageLevelRef = useRef(null);
    const topBannerAdRef = useRef(null);
    const afterContentAdRef = useRef(null);

    const [logoUrl, setLogoUrl] = useState("");
    const [faviconUrl, setFaviconUrl] = useState("");
    const [successMsg, setSuccessMsg] = useState(false);

    const [apiError, setApiError] = useState(false);
    const [btnDisable, setBtnDisable] = useState(false);

    const saveSettings= async (type="publish")=>{
      try{
        setApiError(false);
        setSuccessMsg(false);
        const site_name = (siteTitleRef?.current?.value) ? siteTitleRef?.current?.value : '';
        const home_title = (homePageTitleRef?.current?.value) ? homePageTitleRef?.current?.value : '';
        const home_description = (homePageDescriptionRef?.current?.value) ? homePageDescriptionRef?.current?.value : '';
        const home_top_content = (homePageTopContentRef?.current?.value) ? homePageTopContentRef?.current?.value : '';
        const head_tags = (headTagRef?.current?.value) ? headTagRef?.current?.value : '';
        const google_analytics = (googleAnalyticsRef?.current?.value) ? googleAnalyticsRef?.current?.value : '';
        const adsense_script = (adsensePageLevelRef?.current?.value) ? adsensePageLevelRef?.current?.value : '';
        const top_banner_ad = (topBannerAdRef?.current?.value) ? topBannerAdRef?.current?.value : '';
        const after_content_ad = (afterContentAdRef?.current?.value) ? afterContentAdRef?.current?.value : '';

        setBtnDisable(true);
        const response = await axios.post(secure_api('save-settings'), {
            site_name: site_name,
            home_title: home_title,
            home_description: home_description,
            home_top_content: home_top_content,
            head_tags: head_tags,
            google_analytics: google_analytics,
            adsense_script: adsense_script,
            top_banner_ad: top_banner_ad,
            after_content_ad: after_content_ad
        });
        setBtnDisable(false);
        console.log("response", response);
        if(response?.data?.type=="success"){
            setSuccessMsg("Settings has been saved");
        }
        if(response?.data?.type=='error'){
            setApiError(response?.data?.message);
        }
      }
      catch(e){
        console.log("savePost", e);
        setBtnDisable(false);
      }
    }

    const uploadLogo= async ()=>{
        if(logoRef?.current?.files?.[0]){
            const file = logoRef?.current?.files?.[0];
            const formData = new FormData();
            formData.append("image", file);
            const response = await axios({
                method: "post",
                url: secure_api('upload-logo'),
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            });
            console.log("response uploadlogo", response);
            if(response?.data?.content?.path){
                setLogoUrl(upload_path(response?.data?.content?.path));
            }
        }
    }

    const uploadFavicon = async ()=>{
        if(faviconRef?.current?.files?.[0]){
          const file = faviconRef?.current?.files?.[0];
          const formData = new FormData();
          formData.append("image", file);
          const response = await axios({
              method: "post",
              url: secure_api('upload-favicon'),
              data: formData,
              headers: { "Content-Type": "multipart/form-data" },
          });
          console.log("response uploadfavicon", response);
          if(response?.data?.content?.path){
            setFaviconUrl(upload_path(response?.data?.content?.path));
          }
      }
    }

    useEffect(()=>{
        setBtnDisable(true);
        axios.get(secure_api("get-settings"))
        .then(response=>{
          setBtnDisable(false);
          console.log("response", response);
          if(response?.data?.content){
            let settings = response?.data?.content;
            if(settings?.logo){
                setLogoUrl(upload_path(settings?.logo));
            }
            siteTitleRef.current.value = settings?.site_name;
            homePageTitleRef.current.value = settings?.home_title;
            homePageDescriptionRef.current.value = settings?.home_description;
            homePageTopContentRef.current.value = settings?.home_top_content;
            headTagRef.current.value = settings?.head_tags;
            googleAnalyticsRef.current.value = settings?.google_analytics;
            adsensePageLevelRef.current.value = settings?.adsense_script;
            topBannerAdRef.current.value = settings?.top_banner_ad;
            afterContentAdRef.current.value = settings?.after_content_ad;
          }
        })
        .catch(error=>console.log("get-settings error", error));
    }, []);

  return (
    <>
<MainLayout>
<div className={ "hold-transition sidebar-mini " }>
<div className="wrapper">
  <TopMenu />

  <SiderBar />

  <div className="content-wrapper">

    <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1>Settings</h1>
          </div>
          <div className="col-sm-6">
          </div>
        </div>

        {
          (successMsg) &&
          <div className="row mb-2">
            <div className="col-sm-12">
              <div className="alert alert-success alert-dismissible">
                {successMsg}            
              </div>
            </div>
          </div>
        }

        {
          (apiError) && 
          <div className="row">
            {
              (isIterable(apiError)) 
              ? 
              (
                apiError?.map((data, key)=>{
                  return <div key={key} className="col-sm-12">
                            <ErrorMsg message={data}/>
                        </div>
                })
              )
              : 
              <div className="col-sm-6">
                <ErrorMsg message={apiError}/>
            </div>
            }
          </div>
        }
      </div>
    </section>


    <section className="content">
      <div className="row">
        <div className="col-md-9">
        <div className="card card-default">
          <div className="card-body">

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                    <label htmlFor="exampleInputFile">Logo</label>
                    <div className="input-group">
                      <div className="custom-file">
                        <input type="file" name="logo" className="custom-file-input" id="logo" ref={logoRef} />
                        <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                      </div>
                      <div className="input-group-append">
                        <span className="input-group-text" onClick={uploadLogo}>Upload</span>
                      </div>
                    </div>
                </div>
              </div>
            </div>

            {
                logoUrl && 
                <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                  <img src={logoUrl} style={{height:"200px"}} />
                  </div>
                </div>
                </div>
            }

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                    <label htmlFor="exampleInputFile">Favicon</label>
                    <div className="input-group">
                      <div className="custom-file">
                        <input type="file" name="favicon" className="custom-file-input" id="favicon" ref={faviconRef} />
                        <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                      </div>
                      <div className="input-group-append">
                        <span className="input-group-text" onClick={uploadFavicon}>Upload</span>
                      </div>
                    </div>
                </div>
              </div>
            </div>

            {
                faviconUrl && 
                <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                  <img src={faviconUrl} style={{height:"100px"}} />
                  </div>
                </div>
                </div>
            }

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Site Name</label>
                  <input type="text" className="form-control" ref={siteTitleRef} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Home Page Title</label>
                  <input type="text" className="form-control" ref={homePageTitleRef} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Home Page Description</label>
                  <input type="text" className="form-control" ref={homePageDescriptionRef} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Home Page Top Content</label>
                  <textarea rows={5} className="form-control" defaultValue="" ref={homePageTopContentRef} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Head Tags</label>
                  <textarea rows={5} className="form-control" defaultValue="" ref={headTagRef} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Google Analytics</label>
                  <textarea rows={5} className="form-control" defaultValue="" ref={googleAnalyticsRef} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Adsense Page Level Script</label>
                  <textarea rows={5} className="form-control" defaultValue="" ref={adsensePageLevelRef} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Top Banner Ad</label>
                  <textarea rows={5} className="form-control" defaultValue="" ref={topBannerAdRef} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>After Content Ad</label>
                  <textarea rows={5} className="form-control" defaultValue="" ref={afterContentAdRef} />
                </div>
              </div>
            </div>

          </div>
          <div className="card-footer"></div>
        </div>
        </div>

        <div className="col-md-3">
            <div className="card card-default">
              <div className="card-header">
                <h3 className="card-title">Action</h3>
              </div>

              <form onSubmit={(e)=>e.preventDefault()}>
              
                <div className="card-footer">
                  &nbsp;
                  <button type="submit" className="btn btn-primary" disabled={ (btnDisable) ? "disabled" : "" } onClick={(e)=>saveSettings()}>Save</button>
                </div>
              </form>
            </div>

        </div>

      </div>

    </section>

  </div>

  <Footer />


  <aside className="control-sidebar control-sidebar-dark">

  </aside>

</div>
</div>
</MainLayout>
    </>
  )
}
