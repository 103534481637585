import React, { useState, useRef, useEffect } from 'react'
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom'
import SiderBar from '../partials/SiderBar';
import MainLayout from '../layouts/MainLayout';
import axios from 'axios';
import { secure_api, isIterable, urlFromString, upload_path, backend_url } from '../helper/Helper';
import moment from 'moment-mini';
import ErrorMsg from '../widgets/ErrorMsg';
import Footer from '../partials/Footer';
import TopMenu from '../partials/TopMenu';
import MenuItem from '../partials/MenuItem';

export default function AddMenu(props) {

    const [apiError, setApiError] = useState(false);
    const [btnDisable, setBtnDisable] = useState(false);
    const [startCount, setStartCount] = useState(0);
    const [newMenuCount, setNewMenuCount] = useState(0);
    const [newMenu, setNewMenu] = useState([]);
    const [givenMenu, setGivenMenu] = useState([]);
    const [successMsg, setSuccessMsg] = useState("");

    const addNewMenu = ()=>{

        let countBegin = 0;
        if(newMenuCount>0){
            countBegin = newMenuCount;
        }
        else{
            countBegin = startCount;
        }
        countBegin++;
        
        setNewMenuCount(countBegin);
    }

    useEffect(()=>{
        let array = [];
        let startPoint = startCount;
        startPoint++;
        for(let i=startPoint; i<=newMenuCount; i++){
            array.push(i);
        }
        setNewMenu(array);
    }, [newMenuCount]);

    const saveMenu = async ()=>{

        setApiError(false);
        let menuData = [];
        let menuList = document.querySelectorAll(".menuElement");
        if(isIterable(menuList)){
            for(let menu of menuList){
              let menuId = menu?.id;
              if(menuId){
                menuId = menuId?.replace("menu_elem_", "");
                if(menuId){
                  let menuTitle = document.getElementById("menu_title_"+menuId);
                  let menuUrl = document.getElementById("menu_url_"+menuId);
                  if(menuTitle){
                    menuTitle = menuTitle?.value?.trim();
                  }
                  if(menuUrl){
                    menuUrl = menuUrl?.value?.trim();
                  }
                  if(menuId && menuTitle){
                    menuData.push({
                        menuId: menuId,
                        menuTitle: menuTitle,
                        menuUrl: menuUrl
                    })
                  }
                }
              }
            }
        }
        
        if(isIterable(menuData)){
            setBtnDisable(true);
            const response = await axios.post(secure_api('add-menu'), {
                menuData: menuData,
            });
            setBtnDisable(false);
            console.log("response", response);
            if(response?.data?.type=="success"){
                setSuccessMsg("Menu has been updated");
            }
        }
    }

    useEffect(()=>{
        axios.get(secure_api("get-menu"))
        .then(response=>{
            console.log("menu response", response);
            if(isIterable(response?.data?.content)){
                let lastMenu = response?.data?.content[response?.data?.content?.length-1];
                if(lastMenu?.id){
                    setStartCount(lastMenu?.id);
                }
                setGivenMenu(response?.data?.content);
            }
        })
        .catch(error=>console.log("get-menu error", error));
    }, []);

  return (
    <>
<MainLayout>
<div className={ "hold-transition sidebar-mini " }>
<div className="wrapper">
  <TopMenu />

  <SiderBar />

  <div className="content-wrapper">

    <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1>Add Menu</h1>
          </div>
          <div className="col-sm-6">
          </div>
        </div>

        {
          (successMsg) &&
          <div className="row mb-2">
            <div className="col-sm-12">
              <div className="alert alert-success alert-dismissible">
                {successMsg}               
              </div>
            </div>
          </div>
        }

        {
          (apiError) && 
          <div className="row">
            {
              (isIterable(apiError)) 
              ? 
              (
                apiError?.map((data, key)=>{
                  return <div key={key} className="col-sm-12">
                            <ErrorMsg message={data}/>
                        </div>
                })
              )
              : 
              <div className="col-sm-6">
                <ErrorMsg message={apiError}/>
            </div>
            }
          </div>
        }
      </div>
    </section>


    <section className="content">
      <div className="row">
        <div className="col-md-9">

        <div className="row">
            <div className="col-md-12">
            <div className="form-group">
            <button type="button" className="btn btn-warning" onClick={addNewMenu}>Add Menu +</button>
            </div>
            </div>
        </div>

        {
            givenMenu?.map((menu, key)=>{
                return <div className="card card-default menuElement" id={"menu_elem_"+menu?.id} key={key}>
                    <div className="card-body">
                        <MenuItem key={key} value={key} menu={menu} />
                    </div>
                    <div className="card-footer"></div>
                    </div>
            })
        }

        {
            newMenu?.map((menu, key)=>{
                key++;
                return <div className="card card-default menuElement" id={"menu_elem_"+menu} key={menu}>
                    <div className="card-body">
                        <MenuItem key={menu} value={menu} />
                    </div>
                    <div className="card-footer"></div>
                    </div>
            })
        }

        </div>

        <div className="col-md-3">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Action</h3>
              </div>
              <form onSubmit={(e)=>e.preventDefault()}>
              
                <div className="card-footer">
                  <button type="submit" className="btn btn-primary" disabled={ (btnDisable) ? "disabled" : "" } onClick={(e)=>saveMenu()}>Save Menu</button>
                </div>
              </form>
            </div>

        </div>

      </div>

    </section>

  </div>

  <Footer />


  <aside className="control-sidebar control-sidebar-dark">

  </aside>

</div>
</div>
</MainLayout>
    </>
  )
}
