import React from 'react';

export default function MainLayout(props) {

  return (
    <>
    { props?.children }
    </>
  )
}
