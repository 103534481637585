import React, { useRef, useEffect, useState } from 'react'
import { isIterable, upload_path } from '../helper/Helper';
import MediaCardPreview from './MediaCardPreview'

export default function MediaLibrary(props) {

    const [mediaFiles, setMediaFiles] = useState(props?.mediaFiles);
    const [focusedFile, setFocusedFile] = useState((props?.mediaFiles?.[0]) ? props?.mediaFiles?.[0] : "");
    const [focusAlt, setFocusAlt] = useState("");
    const [focusTitle, setFocusTitle] = useState("");
    const [focusCaption, setFocusCaption] = useState("");
    const [focusFilePath, setFocusFilePath] = useState("");

    useEffect(()=>{
        setFocusFilePath(focusedFile?.path);
        setFocusAlt(focusedFile?.name);
        setFocusTitle(focusedFile?.name);
        setFocusCaption(focusedFile?.name);
    }, [focusedFile]);

    useEffect(()=>{
        setMediaFiles(props?.mediaFiles);
        setFocusedFile((props?.mediaFiles?.[0]) ? props?.mediaFiles?.[0] : "");
    }, [props?.mediaFiles]);

  return (
    <>
    <div className="row mediaLibraryWrap">
        <div className='col-md-8'>
            <div className="mediaLibrary">
                <ul>
                    {
                        (mediaFiles && isIterable(mediaFiles)) &&
                        (
                            mediaFiles?.map((media, key)=>{
                                return <li className={(focusedFile?.id==media?.id ? 'focused' : '')} key={key}>
                                    <MediaCardPreview setFocusedFile={setFocusedFile} media={media} />
                                </li>
                            })
                        )
                    }
                </ul>
            </div>
        </div>
        <div className='col-md-4'>
            {
                (focusTitle && focusFilePath) &&
                <>
                <div className='attachmentInfo'>
                    <div className='thumbnail thumbnailImage'>
                        <img src={ upload_path(focusFilePath) } draggable="false" alt=""/>
                    </div>
                </div>
                <div className='details'>
                    <div className="filename">{focusFilePath}</div>
                    <div className="uploaded">February 5, 2023</div>
                    <div className="file-size">438 KB</div>
                    <div className="dimensions">1600 by 1200 pixels</div>
                    <a href={void(0)}>Delete permanently</a>
                </div>
                <div className='attachmentSettings'>
                    <div className="card-primary">
                        <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Title</label>
                                <textarea type="email" className="form-control" id="exampleInputEmail1" placeholder="Enter Title" onChange={(e)=>setFocusTitle(e.target.value)} value={focusTitle}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Alt Tag</label>
                                <textarea type="email" className="form-control" id="exampleInputEmail1" placeholder="Enter Alt Tag" onChange={(e)=>setFocusAlt(e.target.value)} value={focusAlt}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Caption</label>
                                <textarea type="email" className="form-control" id="exampleInputEmail1" placeholder="Enter Caption" onChange={(e)=>setFocusCaption(e.target.value)} value={focusCaption}/>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            }
        </div>
    </div>
    </>
  )
}
