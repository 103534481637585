import React, { useEffect, useState } from 'react'
import { upload_path } from '../helper/Helper'

export default function GalleryFile(props) {

    const [title, setTitle] = useState((props?.gallery?.title) ? props?.gallery?.title : ((props?.gallery?.name) ? props?.gallery?.name : ""));
    const [altName, setAltName] = useState((props?.gallery?.alt) ? props?.gallery?.alt : ((props?.gallery?.name) ? props?.gallery?.name : ""));
    const [caption, setCaption] = useState((props?.gallery?.caption) ? props?.gallery?.caption : ((props?.gallery?.name) ? props?.gallery?.name : ""));
    const [galleryUrl, setGalleryUrl] = useState(props?.gallery?.url ? props?.gallery?.url : "");
    const [mediaPath, setMediaPath] = useState(((props?.gallery?.media?.[0]?.path) ? props?.gallery?.media?.[0]?.path : (props?.gallery?.path)));
    const [mediaId, setMediaId] = useState((props?.gallery?.media_id ? props?.gallery?.media_id : props?.gallery?.id));

    const changeTitle = (e)=>{
        setTitle(e.target.value);
    }

    const changeAlt = (e)=>{
        setAltName(e.target.value);
    }

    const changeCaption = (e)=>{
        setCaption(e.target.value);
    }

    const changeUrl = (e)=>{
        setGalleryUrl(e.target.value);
    }

    useEffect(()=>{
        setTitle((props?.gallery?.title) ? props?.gallery?.title : ((props?.gallery?.name) ? props?.gallery?.name : ""));
        setAltName((props?.gallery?.alt) ? props?.gallery?.alt : ((props?.gallery?.name) ? props?.gallery?.name : ""));
        setCaption((props?.gallery?.caption) ? props?.gallery?.caption : ((props?.gallery?.name) ? props?.gallery?.name : ""));
        setGalleryUrl(props?.gallery?.url ? props?.gallery?.url : "");
        setMediaPath(((props?.gallery?.media?.[0]?.path) ? props?.gallery?.media?.[0]?.path : (props?.gallery?.path)));
        setMediaId((props?.gallery?.media_id ? props?.gallery?.media_id : props?.gallery?.id));
    }, [props]);

  return (
    <>
{/* <div className="row" draggable={true} onDragStart={(e) => props?.dragStart(e, props?.index)} onDragEnter={(e) => props?.dragEnter(e, props?.index)} onDragEnd={props?.drop}> */}
<div className="row">
    <div className="col-md-12">
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6 galleryImgWrapper">
                        <img className="galleryImgElement" src={upload_path(mediaPath)} id={ "gallery_path_"+(props?.index) } data-mediaid={ mediaId } />
                    </div>
                    <div className="col-md-6">
                        <strong>Title</strong>
                        <div className="input-group mb-3">
                        <input type="text" className="form-control rounded-0" value={title} onChange={changeTitle} id={ "gallery_name_"+(props?.index) } />
                        </div>
                        <strong>Alt</strong>
                        <div className="input-group mb-3">
                        <input type="text" className="form-control rounded-0" value={altName} onChange={changeAlt} id={ "gallery_alt_"+(props?.index) }/>
                        </div>
                        <strong>Caption</strong>
                        <div className="input-group mb-3">
                        <input type="text" className="form-control rounded-0" value={caption} onChange={changeCaption} id={ "gallery_caption_"+(props?.index) }/>
                        </div>
                        <strong>Url</strong>
                        <div className="input-group mb-3">
                        <input type="text" readOnly={true} className="form-control rounded-0" value={galleryUrl} onChange={changeUrl} id={ "gallery_url_"+(props?.index) }/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    </>
  )
}
