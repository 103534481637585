import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { secure_api } from '../helper/Helper';
import { addSiteOptions, addUser } from '../redux/reducers/commonSlice';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

export default function Auth(props) {

    const store = useSelector(state=> state?.common);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showContent, setShowContent] = useState(false);

    useEffect(()=>{
        if(store){
            //console.log("store", store);
            if(store?.user){
                setShowContent(true);
            }
            else{
                let token = localStorage?.getItem("user");
                if(token){
                    checkLogin();
                }
                else{
                    navigate("/login");
                }
            }
        }
    }, [store]);

    const checkLogin= async ()=>{
        try{
            const response = await axios.get(secure_api('userdetails'));
            const settings = await axios.get(secure_api('get-settings'));
            if(response?.data?.type=='success'){
                if(settings?.data?.content){
                    dispatch(addSiteOptions(settings?.data?.content));
                }
                dispatch(addUser(response?.data?.user));
                setShowContent(true);
            }
            else{
                navigate("/login");
            }
        }
        catch(error){
            console.log("checkLogin", error);
        }
    }

  return <>
  {showContent ? props?.children : <Skeleton count={5} />}
  </>;
}
