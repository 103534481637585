export const isIterable=(obj)=>{
    if(obj && typeof obj[Symbol.iterator]==='function'){
        return true;
    }
    return false;
}

export const backend_url=(path)=>{
    let baseUrl = process.env.REACT_APP_BACKEND_URL;
    if(path && path!="/"){
        baseUrl += path;
    }
    return baseUrl;
}

export const upload_path=(path)=>{
    let baseUrl = process.env.REACT_APP_BACKEND_URL+"uploads/";
    if(path){
        baseUrl += path;
    }
    return baseUrl;
}

export const secure_api=(api)=>{
    let apiUrl = process.env.REACT_APP_API_URL;
    if(api){
        apiUrl += api;
    }
    return apiUrl;
}

export const urlFromString=(text)=>{
    if(text){
        text = text?.trim()?.replace(/[^a-zA-Z0-9- ]/g, "")?.trim();
        if(text){
            text = text?.split(" ");
            if(isIterable(text)){
                let finalText = text?.filter(t=>t);
                finalText = finalText?.join("-");
                return finalText?.toLowerCase();
            }
        }
    }
    return null;
}

export const getFileExtension = (filename)=>{
    if(filename){
        let fileArray = filename.split(".");
        if(fileArray.length>0){
            let extension = fileArray.pop();   
            return extension;
        }
    }
    return "";
}