import React, { useState, useRef, useEffect } from 'react'
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom'
import SiderBar from '../partials/SiderBar';
import UploadMediaModal from '../widgets/UploadMediaModal';
import { useSelector } from 'react-redux';
import MainLayout from '../layouts/MainLayout';
import axios from 'axios';
import { secure_api, isIterable, urlFromString, upload_path, backend_url } from '../helper/Helper';
import ErrorMsg from '../widgets/ErrorMsg';
import Footer from '../partials/Footer';
import TopMenu from '../partials/TopMenu';
import RedirectRule from '../partials/RedirectRule';

export default function Redirection(props) {

    const sourceRef = useRef(null);
    const destinationRef = useRef(null);
    const [showModal, setShowModal]= useState(false);
    const [apiError, setApiError] = useState(false);
    const [redirectionRules, setRedirectionRules] = useState([]);

    const addNewRule= async ()=>{
        console.log("add new rule");
        setApiError(false);
        let source = sourceRef?.current?.value;
        let destination = destinationRef?.current?.value;
        if(source && destination){
            const response = await axios.post(secure_api("add-new-rule"), {source: source, destination: destination});
            //console.log("response", response);
            if(response?.data?.type=="success" && response?.data?.content?.length>0){
                setShowModal(false);
                setRedirectionRules(response?.data?.content);
            }
        }
        else{
            setApiError("Source/Destination is required");
        }
    }

    const openModal = ()=>{
        setShowModal(true);
    }

    const closeModal = ()=>{
        setShowModal(false);
    }

    useEffect(()=>{
        sourceRef.current.value = "";
        destinationRef.current.value = "";
    }, [showModal]);

    useEffect(()=>{
        axios.get(secure_api("get-redirections"))
        .then(response=>{
            console.log("response", response);
            if(response?.data?.type=="success" && response?.data?.content?.length>0){
                setRedirectionRules(response?.data?.content);
            }
        })
        .catch(error=>{
            console.log("redirection rule", error);
        })
    }, []);

  return (
    <>
<MainLayout>
<div className={ "hold-transition sidebar-mini " + (showModal ? 'modal-open' : '') }>
<div className="wrapper">
  <TopMenu />

  <SiderBar />

  <div className="content-wrapper">

    <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1>Redirection</h1>
          </div>
          <div className="col-sm-6">
          </div>
        </div>
      </div>
    </section>


    <section className="content">

    <div className="row">
        <div className="col-md-12">
        <div className="card-body">
        <div id="actions" className="row">
            <div className="col-lg-3">
            <div className="btn-group w-100">
                <span className="btn btn-success col fileinput-button dz-clickable" onClick={openModal}>
                <i className="fas fa-plus"></i>
                <span>Add new rule</span>
                </span>
            </div>
            </div>
            </div>
        </div>
        </div>
    </div>

    <div className={ "modal fade "+ (showModal ? "show" : "") } id="modal-default" style={{ display: (showModal ? "block" : "") }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Source</label>
                  <input type="text" className="form-control" ref={sourceRef} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Destination</label>
                  <input type="text" className="form-control" ref={destinationRef} />
                </div>
              </div>
            </div>

            {
                apiError &&
                <div className="row">
                    <ErrorMsg message={apiError} />
                </div>
            }

            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" className="btn btn-default" data-dismiss="modal" onClick={closeModal}>Close</button>
              <button type="button" className="btn btn-primary" onClick={addNewRule}>Save changes</button>
            </div>
          </div>
        </div>
    </div>

    {
        (redirectionRules && redirectionRules?.length>0) &&
        redirectionRules?.map((redirection, key)=>{
            return <RedirectRule redirection={redirection} key={key} setRedirectionRules={setRedirectionRules} />
        })
    }

    </section>

  </div>

  <Footer />

</div>
</div>
</MainLayout>
    </>
  )
}
