import React, { useState, useRef, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import SiderBar from '../partials/SiderBar';
import { Editor } from '@tinymce/tinymce-react';
import UploadMediaModal from '../widgets/UploadMediaModal';
import { useSelector } from 'react-redux';
import MainLayout from '../layouts/MainLayout';
import axios from 'axios';
import { secure_api, isIterable, urlFromString, upload_path, backend_url } from '../helper/Helper';
import moment from 'moment-mini';
import ErrorMsg from '../widgets/ErrorMsg';
import GalleryFile from '../widgets/GalleryFile';
import GalleryFiles from '../widgets/GalleryFiles';
import Footer from '../partials/Footer';
import TopMenu from '../partials/TopMenu';

export default function ViewPosts(props) {

    const [posts, setPosts] = useState([]);

    useEffect(()=>{
        axios.get(secure_api("all-posts"))
        .then(response=>{
            console.log("all-posts response", response);
            if(isIterable(response?.data?.content)){
                setPosts(response?.data?.content);
            }
        })
        .catch(e=> console.log("all-posts error", e))
    }, []);

  return (
    <>
<MainLayout>
<div className={ "hold-transition sidebar-mini " }>
<div className="wrapper">

    <TopMenu />

  <SiderBar />

  <div className="content-wrapper">

    <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1>View Posts ({posts?.length})</h1>
          </div>
          <div className="col-sm-6">
          </div>
        </div>

      </div>
    </section>


    <section className="content">
      <div className="row">
        <div className="col-md-12">
        <div className="card card-default">
          <div className="card-body">

            <div className="row">
              <div className="col-md-12">
              <div className="card">
                <div className="card-body p-0">
                    <table className="table table-striped projects">
                    <thead>
                        <tr>
                        <th style={{width: "5%"}}></th>
                        <th style={{width: "35%"}}>Title</th>
                        <th style={{width: "15%"}}>Publish Date</th>
                        <th style={{width: "15%"}}>Modified Date</th>
                        <th style={{width: "10%"}}>Status</th>
                        <th style={{width: "20%", textAlign:"center"}}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            posts?.map((post, key)=>{
                                return <tr key={key}>
                                <td>{++key}</td>
                                <td>{post?.title}</td>
                                <td>{moment(post?.created_at).format('D-MMM-Y H:mm:ss')}</td>
                                <td>{moment(post?.updated_at).format('D-MMM-Y H:mm:ss')}</td>
                                <td><span className={ "badge badge-"+(post?.status=="publish" ? 'success' : 'warning') }>{post?.status}</span></td>
                                <td className="project-actions text-right">
                                    <a className="btn btn-primary btn-sm" target="_blank" href={ backend_url(post?.url) }>
                                        <i className="fas fa-folder">
                                        </i>
                                        View
                                    </a>
                                    <a className="btn btn-info btn-sm" href={ "/edit-post/"+post?.id }>
                                        <i className="fas fa-pencil-alt">
                                        </i>
                                        Edit
                                    </a>
                                    {/* <a className="btn btn-danger btn-sm" href="#">
                                        <i className="fas fa-trash">
                                        </i>
                                        Delete
                                    </a> */}
                                </td>
                                </tr>
                            })
                        }
                    </tbody>
                    </table>
                </div>
            </div>
              </div>
            </div>

          </div>
          <div className="card-footer"></div>
        </div>
        </div>

      </div>

    </section>

  </div>

  <Footer />


  <aside className="control-sidebar control-sidebar-dark">

  </aside>

</div>
</div>
</MainLayout>
    </>
  )
}
