import React, { useEffect, useRef, useState } from 'react'
import ErrorMsg from '../widgets/ErrorMsg';
import axios from 'axios';
import { secure_api } from '../helper/Helper';
import SuccessMsg from '../widgets/SuccessMsg';

export default function RedirectRule(props) {

  const [id, setId] = useState(props?.redirection?.id)
  const [fromUrl, setFromUrl] = useState(props?.redirection?.from_url);
  const [destinationUrl, setDestinationUrl] = useState(props?.redirection?.to_url);
  const [apiError, setApiError] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);

  useEffect(()=>{
    setFromUrl(props?.redirection?.from_url);
    setDestinationUrl(props?.redirection?.to_url);
    setId(props?.redirection?.id);
  }, [props?.redirection]);

  const resetForm=()=>{
    setApiError(false);
    setSuccessMsg(false);
  }

  const saveRule= async ()=>{
    resetForm();
    if(id && fromUrl && destinationUrl){
      const response = await axios.post(secure_api("update-rule"), {id: id, source: fromUrl, destination: destinationUrl});
      //console.log("response", response);
      if(response?.data?.type=="success" && response?.data?.content?.length>0){
        setSuccessMsg("Content has been updated");
        props?.setRedirectionRules(response?.data?.content);
      }
    }
    else{
      setApiError("Source/Destination is required");
    }
  }

  const deleteRule = async ()=>{
    resetForm();
    if(id && fromUrl && destinationUrl){
      const response = await axios.post(secure_api("delete-rule"), {id: id, source: fromUrl, destination: destinationUrl});
      console.log("response", response);
      if(response?.data?.type=="success" && response?.data?.content?.length>0){
        setSuccessMsg("Content has been deleted");
        props?.setRedirectionRules(response?.data?.content);
      }
    }
    else{
      setApiError("Source/Destination is required");
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-md-9">
        <div className="card card-default">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Source</label>
                  <input type="text" className="form-control" value={fromUrl} onChange={(e)=>setFromUrl(e.target.value)} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Destination</label>
                  <input type="text" className="form-control" value={destinationUrl} onChange={(e)=>setDestinationUrl(e.target.value)} />
                </div>
              </div>
            </div>

            {
                apiError &&
                <div className="row">
                    <ErrorMsg message={apiError} />
                </div>
            }

            {
              successMsg &&
              <div className="row">
              <SuccessMsg message={successMsg} />
              </div>
            }

          </div>
          <div className="card-footer">
            <button type="submit" className="btn btn-default" onClick={deleteRule}>Delete</button>
            <button type="submit" className="btn btn-primary float-right" onClick={saveRule}>Save</button>
          </div>
        </div>
        </div>

      </div>
    </>
  )
}
