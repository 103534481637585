import React from 'react'
import { Link } from 'react-router-dom'

export default function TopMenu() {
  return (
    <>
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
        <li className="nav-item">
        </li>
        <li className="nav-item d-none d-sm-inline-block">
            <Link to="/" className="nav-link">Home</Link>
        </li>
        </ul>
    </nav>
    </>
  )
}
