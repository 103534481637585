import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { constants } from '../config/constants';
import { secure_api } from '../helper/Helper';
import MainLayout from '../layouts/MainLayout';
import ErrorMsg from '../widgets/ErrorMsg';

export default function Login() {

    const store = useSelector(state=> state?.common);
    const navigate = useNavigate();
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [apiErrors, setApiErrors] = useState([]);

    const loginUser = (e)=>{
        e.preventDefault();
        setEmailError("");
        setPasswordError("");
        setApiErrors([]);
        let email = (emailRef?.current?.value ?? null);
        let password = (passwordRef?.current?.value ?? null);
        if(!email){
            setEmailError(constants?.email?.required);
        }
        if(!password){
            setPasswordError(constants?.password?.required);
        }
        if(email && password){
            setBtnDisabled(true);
            axios.post(secure_api("login"), {
                email: email,
                password: password
            })
            .then(response=>{
                setBtnDisabled(false);
                //console.log("response", response);
                if(response?.data){
                    if(response?.data?.type=='error'){
                        setApiErrors(response?.data?.message);
                    }
                    else if(response?.data?.type=='success'){
                        if(response?.data?.user?.token){
                            localStorage.setItem('user', JSON.stringify(response?.data?.user?.token));
                            navigate('/');
                        }
                    }
                }
            })
            .catch(error=>{
                console.log("error", error);
                setBtnDisabled(false);
            })
        }
    }

    useEffect(()=>{
        //console.log("store", store)
        if(store?.user){
            navigate('/');
        }
    }, [store]);

  return (
    <>
    <MainLayout>
    <div className="hold-transition login-page">
        <div className="login-box">
        <div className="card">
            <div className="card-body login-card-body">
            <p className="login-box-msg">Sign in to start your session</p>

            <form action="" method="post" onSubmit={loginUser}>
                <div className="input-group mb-3">
                <input type="email" className="form-control" placeholder="Email" ref={emailRef}/>
                <div className="input-group-append">
                    <div className="input-group-text">
                    <span className="fas fa-envelope"></span>
                    </div>
                </div>
                <ErrorMsg message={emailError} />
                </div>
                <div className="input-group mb-3">
                <input type="password" className="form-control" placeholder="Password" ref={passwordRef}/>
                <div className="input-group-append">
                    <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                    </div>
                </div>
                <ErrorMsg message={passwordError} />
                </div>
                {
                    (apiErrors && apiErrors?.length>0) &&
                    <div className="row">
                        {
                            apiErrors?.map((error, key)=>{
                                return <ErrorMsg key={key} message={error} />
                            })
                        }
                        <span>&nbsp;</span>
                    </div>
                }
                <div className="row">
                <div className="col-4">
                    <button type="submit" disabled={ btnDisabled ? 'disabled' : '' } className="btn btn-primary btn-block">Sign In</button>
                </div>
                </div>
            </form>
            
            {/* <p className="mb-1">
                <a href="forgot-password.html">I forgot my password</a>
            </p> */}
            </div>
        </div>
        </div>
    </div>
    </MainLayout>
    </>
  )
}
