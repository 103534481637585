import React, { useState, useRef, useEffect } from 'react'
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom'
import SiderBar from '../partials/SiderBar';
import { Editor } from '@tinymce/tinymce-react';
import UploadMediaModal from '../widgets/UploadMediaModal';
import { useSelector } from 'react-redux';
import MainLayout from '../layouts/MainLayout';
import axios from 'axios';
import { secure_api, isIterable, urlFromString, upload_path, backend_url } from '../helper/Helper';
import moment from 'moment-mini';
import ErrorMsg from '../widgets/ErrorMsg';
import GalleryFile from '../widgets/GalleryFile';
import GalleryFiles from '../widgets/GalleryFiles';
import Footer from '../partials/Footer';
import TopMenu from '../partials/TopMenu';
import PublishButtons from '../partials/PublishButtons';

export default function AddNewPage(props) {

    let {id} = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const editorRef = useRef(null);
    const titleRef = useRef(null);
    const urlRef = useRef(null);
    const metaTitleRef = useRef(null);
    const metaDescriptionRef = useRef(null);
    const createdDateRef = useRef(null);
    const monthRef = useRef(null);
    const dateRef = useRef(null);
    const yearRef = useRef(null);
    const hourRef = useRef(null);
    const minRef = useRef(null);

    const [postContent, setPostContent] = useState(null);
    const [editorContent, setEditorContent] = useState("<p></p>");
    const [dateError, setDateError] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [btnDisable, setBtnDisable] = useState(false);

    let now = moment();
    let year = now.get('year');
    let month = now.get('month');
    month++;
    let date = now.get('date');
    let hour = now.get('hour');
    let minute = now.get('minute');

    const startTyping=()=>{
      if(apiError){
        setApiError(false);
      }
    }

    const generateUrl=()=>{
      if(!metaTitleRef.current.value){
        metaTitleRef.current.value = titleRef?.current?.value;
      }
      if(!metaDescriptionRef.current.value){
        metaDescriptionRef.current.value = titleRef?.current?.value;
      }
      if(titleRef?.current?.value && !urlRef.current.value){
        let url = urlFromString(titleRef?.current?.value);
        if(url){
          urlRef.current.value = url;
        }
      }
    }

    const savePost= async (type="publish")=>{
      try{
        setDateError(false);
        setApiError(false);
        const month = (monthRef?.current?.value) ? monthRef?.current?.value : '';
        const date = (dateRef?.current?.value) ? dateRef?.current?.value : '';
        const year = (yearRef?.current?.value) ? yearRef?.current?.value : '';
        const hour = (hourRef?.current?.value) ? hourRef?.current?.value : '';
        const min = (minRef?.current?.value) ? minRef?.current?.value : '';
        let selectedDate = `${year}-${month}-${date} ${hour}:${min}`;
        selectedDate = moment(selectedDate).format("YYYY-MM-DD HH:mm");
        let isDateValid = moment(selectedDate).isValid();

        if(isDateValid){
          if(titleRef?.current?.value && urlRef?.current?.value && editorRef.current.getContent()){
            const title = (titleRef?.current?.value) ? titleRef?.current?.value : '';
            const url = (urlRef?.current?.value) ? urlRef?.current?.value : '';
            const content = (editorRef.current.getContent()) ? editorRef.current.getContent() : '';
            const metaTitle = (metaTitleRef?.current?.value) ? metaTitleRef?.current?.value : '';
            const metaDescription = (metaDescriptionRef?.current?.value) ? metaDescriptionRef?.current?.value : '';
            const createdDate = (createdDateRef?.current?.value) ? createdDateRef?.current?.value : '';
            const publish_type = type;

            setBtnDisable(true);
            const response = await axios.post(secure_api('add-new-page'), {
              title: title,
              url: url,
              content: content,
              meta_title: metaTitle,
              meta_description: metaDescription,
              createdDate: createdDate,
              status: publish_type,
              contentId: (id ? id : '')
            });
            setBtnDisable(false);
            console.log("response", response);
            if(response?.data?.type=='success'){
              if(response?.data?.content?.id){
                navigate("/edit-page/"+response?.data?.content?.id+"?message=published");
              }
            }
            if(response?.data?.type=='error'){
              setApiError(response?.data?.message);
            }
          }
          else{
            setApiError([
              'Title is required',
              'Url is required',
              'Content is required'
            ])
          }
        }
        else{
          setDateError("Date in invalid");
        }
      }
      catch(e){
        console.log("savePost", e);
        setBtnDisable(false);
      }
    }

    useEffect(()=>{
      if(id){
        setBtnDisable(true);
        axios.get(secure_api("get-page/"+id))
        .then(response=>{
          setBtnDisable(false);
          if(response?.data?.content){
            let content = response?.data?.content;
            titleRef.current.value = content?.title;
            urlRef.current.value = content?.url;
            setEditorContent(content?.content);
            metaTitleRef.current.value = content?.meta_title;
            metaDescriptionRef.current.value = content?.meta_description;

            setPostContent(response?.data?.content);
          }
          else{
            setApiError(['Content not found'])
            window.location.href = "/add-new-page";
          }
          console.log("response", response);
        })
        .catch(error=>console.log("get-page error", error));
        //console.log("id", id)
      }
    }, [id]);

  return (
    <>
<MainLayout>
<div className={ "hold-transition sidebar-mini " }>
<div className="wrapper">
  <TopMenu />

  <SiderBar />

  <div className="content-wrapper">

    <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1>{id ? "Edit Page" : "Add New Page"}</h1>
          </div>
          <div className="col-sm-6">
          </div>
        </div>

        {
            (searchParams?.get('message') && urlRef?.current?.value) &&
            <div className="row mb-2">
            <div className="col-sm-12">
            <div className="alert alert-success alert-dismissible">
                Page has been published, view - &nbsp;
                <a target="_blank" href={ backend_url("page/"+urlRef.current.value) }>{ backend_url("page/"+urlRef.current.value) }</a>
            </div>
            </div>
            </div>
        }

        {
          (apiError) && 
          <div className="row">
            {
              (isIterable(apiError)) 
              ? 
              (
                apiError?.map((data, key)=>{
                  return <div key={key} className="col-sm-12">
                            <ErrorMsg message={data}/>
                        </div>
                })
              )
              : 
              <div className="col-sm-6">
                <ErrorMsg message={apiError}/>
            </div>
            }
          </div>
        }
      </div>
    </section>


    <section className="content">
      <div className="row">
        <div className="col-md-9">
        <div className="card card-default">
          <div className="card-body">

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Title</label>
                  <input type="text" className="form-control" ref={titleRef} onKeyUp={startTyping} onBlur={generateUrl} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Url</label>
                  <input type="text" readOnly={id ? true : false} className="form-control" ref={urlRef} onKeyUp={startTyping}/>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Content</label>
                  <div className='editorWrap'>
                  {/* <div className='col-md-3'>
                        <button type="button" className="btn btn-block btn-default imageUploadBtn" onClick={()=>setIsOpenModal(true)}><i className="fas fa-camera"></i> Add Media</button>
                  </div> */}
                  <Editor
                    tinymceScriptSrc="/js/tinymce/tinymce.min.js"
                    onInit={(evt, editor) => editorRef.current = editor}
                    initialValue={editorContent}
                    init={{
                        promotion:false,
                        branding:false,
                        height: 500,
                        menubar: false,
                        plugins: 'link image code lists wordcount',
                        toolbar: 'styles | bold italic | numlist bullist | alignleft aligncenter alignright | link | code',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        selector:'textarea',
                        content_css:"/tinymce.css"
                    }}
                />
                </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Meta Title</label>
                  <input type="text" className="form-control" ref={metaTitleRef} onKeyUp={startTyping}/>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Meta Description</label>
                  <textarea className="form-control" defaultValue="" ref={metaDescriptionRef} onKeyUp={startTyping}/>
                </div>
              </div>
            </div>

          </div>
          <div className="card-footer"></div>
        </div>
        </div>

        <div className="col-md-3">
              <PublishButtons date={date} dateRef={dateRef} month={month} monthRef={monthRef} year={year} yearRef={yearRef} hour={hour} hourRef={hourRef} minute={minute} minRef={minRef} dateError={dateError} id={id} postContent={postContent} btnDisable={btnDisable} savePost={savePost} />
        </div>

      </div>

    </section>

  </div>

  <Footer />


  <aside className="control-sidebar control-sidebar-dark">

  </aside>

</div>
</div>
</MainLayout>
    </>
  )
}
