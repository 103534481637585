import React from 'react'
import { Link, useLocation } from 'react-router-dom'

export default function NavItem(props) {

    const location = useLocation();

  return (
    <>
        <li className={ "nav-item " + ((location?.pathname==props?.link) ? 'active' : '') }>
        <a href={props?.link} className="nav-link">
            <i className="nav-icon fas fa-edit"></i>
            <p>{props?.name}</p>
        </a>
        </li>
    </>
  )
}
