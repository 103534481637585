import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { secure_api, upload_path } from '../helper/Helper';
import { setIsModalOpen, setSelectedFiles } from '../redux/reducers/mediaModalSlice';
import MediaLibrary from './MediaLibrary';
import UploadMedia from './UploadMedia';

export default function UploadMediaModal(props) {

    const {common, mediamodal} = useSelector(state =>state);
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const [activeTab, setActiveTab] = useState("uploadmedia");
    const [mediaFiles, setMediaFiles] = useState([]);

    const [totalSelectedFilesToUpload, setTotalSelectedFilesToUpload] = useState(0); 
    const [successfullyUploadedFiles, setSuccessfullyUploadedFiles] = useState(0);
    const [uploadStatus, setUploadStatus] = useState("");

    const getAllFiles = ()=>{
        axios.get(secure_api('all-media'))
        .then(response=>{
            console.log("response", response);
            if(response?.data?.type=="success"){
                setMediaFiles(response?.data?.message);
            }
        })
        .catch(error=> console.log('uploadmediamodel axios error', error))
    }

    useEffect(()=>{
        if(mediamodal?.isOpenModal){
            getAllFiles();
        }
    }, [mediamodal?.isOpenModal]);

    const tabs = [
        {
            name: 'Upload File',
            shortname: 'uploadmedia'
        },
        {
            name: 'Media Library',
            shortname: 'medialibrary'
        }
    ];

    useEffect(()=>{
        if(mediamodal?.isOpenModal){
          document.body.style.overflow = 'hidden'; 
        }
        else{
            document.body.style.overflow = '';
            resetModel();
        }
    }, [mediamodal?.isOpenModal]);

    const resetModel=()=>{
        setActiveTab("uploadmedia");
        setMediaFiles([]);
        dispatch(setSelectedFiles([]));
        setTotalSelectedFilesToUpload(0);
        setSuccessfullyUploadedFiles(0);
        setUploadStatus("");
    }

    const uploadSingleImage= async (file)=>{
        if(file){
            const formData = new FormData();
            formData.append("image", file);
            const response = await axios({
                method: "post",
                url: secure_api('upload-image'),
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            });
            //console.log("response of upload", response);
            if(response?.data?.type=='success'){
                if(response?.data?.content && response?.data?.all_images){
                    setSuccessfullyUploadedFiles((prev)=>{
                        return ++prev;
                    });
                    return response?.data;
                    //setMediaFiles(response?.data?.all_images);
                }
            }
            else{

            }
        }
        return null;
    }

    const fileUpload= async (e)=>{
        setTotalSelectedFilesToUpload(0);
        setSuccessfullyUploadedFiles(0);
        if(e?.target?.files && e?.target?.files?.length>0){
            let files = e?.target?.files;
            let filesArray = [];
            setUploadStatus("uploading start...");
            setTotalSelectedFilesToUpload(e?.target?.files?.length);
            setActiveTab("medialibrary");
            for(let file of files){
                filesArray.push(
                    uploadSingleImage(file)
                );
            }
            if(filesArray?.length>0){
                let fileResponses = await Promise.all(filesArray);
                console.log("fileResponses", fileResponses);
                setUploadStatus("uploading successfull");
                getAllFiles();
            }
        }
    }

    const insertIntoPostData=()=>{
        if(mediamodal?.selectedFiles?.length>0){
            let fileString = "";
            for(let file of mediamodal?.selectedFiles){
                if(file?.path && file?.name){
                    fileString += `<img src="${upload_path(file?.path)}" alt="${file?.name}"/>`;
                }
            }
            if(fileString){
                props?.insertIntoPost(fileString);
                dispatch(setSelectedFiles([]));
            }
        }
    }

    const insertIntoGalleryData=()=>{
        if(mediamodal?.selectedFiles?.length>0){
            props?.insertIntoGallery(mediamodal?.selectedFiles);
            dispatch(setSelectedFiles([]))
        }
    }
    
  return (
    <>
    <div className={ "uploadMediaModal modal fade " + (mediamodal?.isOpenModal ? 'show' : '') } id="modal-xl" style={{ display:(mediamodal?.isOpenModal ? 'block' : 'none') }} >
        <div className="modal-dialog modal-xl">
        <div className="modal-content">
            <div className="modal-header">
            <h4 className="modal-title">Add media</h4>
            <div className='mediaStatus'>
                {
                    totalSelectedFilesToUpload>0 &&
                    <div>Files to upload - {totalSelectedFilesToUpload}</div>
                }

                {
                    successfullyUploadedFiles>0 &&
                    <div>Success uploading - {successfullyUploadedFiles}</div>
                }

                {
                    uploadStatus && <div>{uploadStatus}</div>
                }
            </div>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>dispatch(setIsModalOpen(false))}>
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div className="modal-body">

            <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
            {
                (tabs && tabs?.length>0) &&
                <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                    {
                        tabs.map((tab, key)=>{
                            return <li key={key} className="nav-item" onClick={()=>setActiveTab(tab?.shortname)}>
                            <a className={ "nav-link " + ((activeTab==tab?.shortname) ? 'active' : '') } id="custom-tabs-four-home-tab" data-toggle="pill" href={void(0)} role="tab" aria-controls="custom-tabs-four-home" aria-selected="true">{tab?.name}</a>
                            </li>
                        })
                    }
                </ul>
            }
            </div>
            <div className="card-body">
            <div className="tab-content" id="custom-tabs-four-tabContent">
                {
                    (activeTab=='uploadmedia') ? 
                    <div className="addFiles tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
                        <div className='col-md-3 offset-4'>
                            <span className="btn btn-default col fileinput-button" onClick={()=>inputRef?.current?.click()}>
                                <i className="fas fa-plus"></i>
                                <span>Add files</span>
                                <UploadMedia fileUpload={fileUpload} inputRef={inputRef} />
                            </span>
                        </div>
                    </div>
                    : 
                    <MediaLibrary mediaFiles={mediaFiles} />
                }
            </div>
            </div>
        </div>

            </div>
            <div className="modal-footer justify-content-between">
                <div>
                    <button type="button" className="btn btn-default" data-dismiss="modal" onClick={()=>dispatch(setIsModalOpen(false))}>Close</button>
                </div>
                <div>
                <button type="button" className="btn btn-primary" onClick={(e)=>insertIntoGalleryData(e)}>Insert into Gallery</button> &nbsp;
                {/* <button type="button" className="btn btn-primary" onClick={(e)=>insertIntoPostData(e)}>Insert into post</button> */}
                </div>
            </div>
        </div>
        </div>
    </div>
    </>
  )
}
