import React from 'react';
import moment from 'moment-mini';

export default function PublishButtons(props) {

  return (
    <>
    <div className="card card-primary">
        <div className="card-header">
        <h3 className="card-title">Publish</h3>
        </div>
        <div className="card-body">
        {
        (props?.postContent?.status) &&
        <>
        <label>Status</label>
        <div className={ "publishData "+(props?.postContent?.status=="publish" ? 'success' : 'warning') }>
        {props?.postContent?.status}
        </div>
        </>
        }
        {
        (props?.postContent?.created_at) &&
        <>
        <label>Published Date</label>
        <div className='publishData'>
        {moment(props?.postContent?.created_at).format('D-MMM-Y H:mm:ss')}
        </div>
        </>
        }

        <label>{(props?.id) ? "Modified" : "Published"} Date</label>
        <div className="row publishDate">
            <div className="col-md-4">
                <select className="form-control" defaultValue={props?.month} ref={props?.monthRef}>
                    <option value="1">Jan</option>
                    <option value="2">Feb</option>
                    <option value="3">Mar</option>
                    <option value="4">Apr</option>
                    <option value="5">May</option>
                    <option value="6">Jun</option>
                    <option value="7">Jul</option>
                    <option value="8">Aug</option>
                    <option value="9">Sep</option>
                    <option value="10">Oct</option>
                    <option value="11">Nov</option>
                    <option value="12">Dec</option>
                </select>
            </div>
            <div className="col-md-3">
                <select className="form-control" defaultValue={props?.date} ref={props?.dateRef}>
                    {
                    Array.from(Array(31).keys()).map((date, key)=>{
                        date++;
                        return <option key={key} value={date}>{date}</option>
                    })
                    }
                </select>
            </div>
            <div className="col-md-4">
                <select className="form-control" defaultValue={props?.year} ref={props?.yearRef}>
                {
                    Array.from(Array(10).keys()).map((date, key)=>{
                        date = date+2023;
                        return <option key={key} value={date}>{date}</option>
                    })
                    }
                </select>
            </div>
        </div>
        <div className="row"><div className="col-md-3">at</div></div>
        <div className="row publishDate">
            <div className="col-md-3">
                <input ref={props?.hourRef} type="text" className="form-control" title="" defaultValue={props?.hour}/>
            </div>
            <div className="col-md-1">
                :
            </div>
            <div className="col-md-3">
                <input ref={props?.minRef} type="text" className="form-control" title="" defaultValue={props?.minute}/>
            </div>
        </div>
        {props?.dateError}
        </div>
        <form onSubmit={(e)=>e.preventDefault()}>
        
        <div className="card-footer">
        {(!props?.postContent?.status) && <button type="submit" className="btn btn-primary" disabled={ (props?.btnDisable) ? "disabled" : "" } onClick={(e)=>props?.savePost('draft')}>Draft</button>}
        &nbsp;
        <button type="submit" className="btn btn-primary" disabled={ (props?.btnDisable) ? "disabled" : "" } onClick={(e)=>props?.savePost()}>{(!props?.postContent?.status || props?.postContent?.status=="draft") ? 'Publish' : 'Update'}</button>
        </div>
        </form>
    </div>
    </>
  )
}
