import React, { useEffect, useRef } from 'react'

export default function UploadMedia(props) {

  return (
    <>
    <form method="post" encType="multipart/form-data">
        <input ref={props?.inputRef} onChange={(e)=>props?.fileUpload(e)} className='uploadImg' type="file" name="files" id="files" accept="image/png, image/gif, image/jpeg" multiple/>
    </form>
    </>
  )
}
