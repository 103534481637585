import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { secure_api } from '../helper/Helper';
import { addUser } from '../redux/reducers/commonSlice';

export default function WithoutLogin(props) {

    const store = useSelector(state =>state?.common);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showContent, setShowContent] = useState(false);

    useEffect(()=>{
        if(store?.user){
            navigate("/");
        }
        else{
            let token = localStorage?.getItem("user");
            if(token){
                checkLogin();
            }
            else{
                setShowContent(true);
            }
        }
    }, [store]);

    const checkLogin= async ()=>{
        try{
            const response = await axios.get(secure_api('userdetails'));
            if(response?.data?.type=='success'){
                dispatch(addUser(response?.data?.user));
                navigate("/");
            }
            else{
                setShowContent(true);
            }
        }
        catch(error){
            console.log("checkLogin", error);
        }
    }

  return (
    <>{showContent && props?.children}</>
  )
}
