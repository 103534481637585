import React from 'react'

export default function SuccessMsg(props) {
  return (
    <>
    {
        props?.message && <span id="exampleInputEmail1-error" className="form error invalid-feedback" style={{color: "green"}}>* {props?.message}</span>
    }
    </>
  )
}
