export const constants = {
    "name" : {
        "required": "Name is required"
    },
    "email" : {
        "required": "Email is required"
    },
    "password" : {
        "required": "Password is required"
    }
}