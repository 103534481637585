import React from 'react'

export default function MenuItem(props) {
  return (
    <>
    <div className="row">
        <div className="col-md-12">
        <div className="form-group">
            <label>Title</label>
            <input type="text" className="form-control" id={ "menu_title_"+(props?.menu?.id ? props?.menu?.id : props?.value) } defaultValue={props?.menu?.title} />
        </div>
        </div>
    </div>

    <div className="row">
        <div className="col-md-12">
        <div className="form-group">
            <label>Url</label>
            <input type="text" className="form-control" id={ "menu_url_"+(props?.menu?.id ? props?.menu?.id : props?.value) } defaultValue={props?.menu?.url} />
        </div>
        </div>
    </div>
    </>
  )
}
