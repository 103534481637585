import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { isIterable, secure_url, upload_path } from '../helper/Helper';
import { setSelectedFiles } from '../redux/reducers/mediaModalSlice';

export default function MediaCardPreview(props) {

  const {common, mediamodal} = useSelector(state =>state);
  const dispatch = useDispatch();
  const [media, setMedia] = useState(props?.media);

  // useEffect(()=>{
  //   console.log("selectedFiles", mediamodal?.selectedFiles);
  // }, [mediamodal?.selectedFiles])

  useEffect(()=>{
    setMedia(props?.media);
  }, [props?.media]);

  const makeFocus=()=>{
    props?.setFocusedFile(media);
  }

  const makeSelect=(e)=>{
    if(e.target.checked){
      makeFocus();
    }
    let mediaId = e.target.id;
    mediaId = mediaId.split("_");
    mediaId = mediaId?.[1];
    if(e.target.checked){
      let checkifexist = mediamodal?.selectedFiles?.filter(m=> m?.id==mediaId);
      if(checkifexist?.length==0){
        let newSelectedFiles = [...mediamodal?.selectedFiles, media];
        dispatch(setSelectedFiles(newSelectedFiles));
      }
    }
    else{
      let removeFromSelected = mediamodal?.selectedFiles?.filter(m=> m?.id!=mediaId);
      dispatch(setSelectedFiles(removeFromSelected));
    }
  }

  return (
    <>
    {
      (media?.name && media?.path) &&
      <>
        <div className="attachmentPreview" onClick={()=>makeFocus()}>
            <div className="thumbnail">
                <div className="centered">
                    <img src={ upload_path(media?.path) } alt={media?.name} />
                </div>
            </div>
        </div>
        <input type="checkbox" id={"media_"+media?.id} onChange={(e)=>makeSelect(e)} />
      </>
    }
    </>
  )
}
