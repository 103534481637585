import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpenModal: false,
    selectedFiles: []
}

export const mediaModalSlice = createSlice({
    name: 'mediamodal',
    initialState,
    reducers: {
        setIsModalOpen: (state, action)=>{
            state.isOpenModal = action.payload
        },
        setSelectedFiles: (state, action)=>{
            state.selectedFiles = action.payload
        }
    }
});

export const { setIsModalOpen, setSelectedFiles } = mediaModalSlice.actions;
export default mediaModalSlice.reducer;