import React, {useEffect, useRef, useState} from 'react'
import { isIterable } from '../helper/Helper';
import GalleryFile from './GalleryFile'

export default function GalleryFiles(props) {

    const [galleryList, setGalleryList] = useState(props?.galleryFiles);

    useEffect(()=>{
        setGalleryList(props?.galleryFiles);
    }, [props?.galleryFiles]);

    const dragItem = useRef();
    const dragOverItem = useRef();

    const dragStart = (e, position) => {
        dragItem.current = position;
        //console.log(e.target.innerHTML);
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
        //console.log(e.target.innerHTML);
    };

    const drop = (e) => {
        const copyListItems = [...galleryList];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setGalleryList(copyListItems);
    };

  return (
    <>
    {
        (galleryList && isIterable(galleryList)) &&
        galleryList?.map((gallery, key)=>{
            let index = ++key;
            return <GalleryFile key={key} gallery={gallery} dragStart={dragStart} dragEnter={dragEnter} drop={drop} index={index} />
        })
    }
    </>
  )
}
