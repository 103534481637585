import React, { useState, useRef, useEffect } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import SiderBar from '../partials/SiderBar';
import { Editor } from '@tinymce/tinymce-react';
import UploadMediaModal from '../widgets/UploadMediaModal';
import { useDispatch, useSelector } from 'react-redux';
import { setIsModalOpen  } from '../redux/reducers/mediaModalSlice';
import MainLayout from '../layouts/MainLayout';
import axios from 'axios';
import { secure_api, isIterable, urlFromString, upload_path, backend_url } from '../helper/Helper';
import moment from 'moment-mini';
import ErrorMsg from '../widgets/ErrorMsg';
import GalleryFile from '../widgets/GalleryFile';
import GalleryFiles from '../widgets/GalleryFiles';
import Footer from '../partials/Footer';
import TopMenu from '../partials/TopMenu';
import PublishButtons from '../partials/PublishButtons';

export default function AddNewPost(props) {

    const {common, mediamodal} = useSelector(state =>state);
    const dispatch = useDispatch();
    let {id} = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const editorRef = useRef(null);
    const titleRef = useRef(null);
    const urlRef = useRef(null);
    const contentRef = useRef(null);
    const metaTitleRef = useRef(null);
    const metaDescriptionRef = useRef(null);
    const createdDateRef = useRef(null);
    const categoryRef = useRef(null);
    const featuredImageRef = useRef(null);
    const monthRef = useRef(null);
    const dateRef = useRef(null);
    const yearRef = useRef(null);
    const hourRef = useRef(null);
    const minRef = useRef(null);
    const contentTypeRef = useRef(null);

    const [postContent, setPostContent] = useState(null);
    const [editorContent, setEditorContent] = useState("<p></p>");
    // const [isOpenModal, setIsOpenModal] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [galleryFiles, setGalleryFiles] = useState([]);
    const [btnDisable, setBtnDisable] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);

    let now = moment();
    let year = now.get('year');
    let month = now.get('month');
    month++;
    let date = now.get('date');
    let hour = now.get('hour');
    let minute = now.get('minute');

    // const log = () => {
    //   if (editorRef.current) {
    //     console.log(editorRef.current.getContent());
    //   }
    // };  

    const startTyping=()=>{
      if(apiError){
        setApiError(false);
      }
    }

    const generateUrl=()=>{
      if(!metaTitleRef.current.value){
        metaTitleRef.current.value = titleRef?.current?.value;
      }
      if(!metaDescriptionRef.current.value){
        metaDescriptionRef.current.value = titleRef?.current?.value;
      }
      if(titleRef?.current?.value && !urlRef.current.value){
        let url = urlFromString(titleRef?.current?.value);
        if(url){
          urlRef.current.value = url;
        }
      }
    }

    const savePost= async (type="publish")=>{
      try{
        setDateError(false);
        setApiError(false);
        setSuccessMsg(false);
        const month = (monthRef?.current?.value) ? monthRef?.current?.value : '';
        const date = (dateRef?.current?.value) ? dateRef?.current?.value : '';
        const year = (yearRef?.current?.value) ? yearRef?.current?.value : '';
        const hour = (hourRef?.current?.value) ? hourRef?.current?.value : '';
        const min = (minRef?.current?.value) ? minRef?.current?.value : '';
        let selectedDate = `${year}-${month}-${date} ${hour}:${min}`;
        selectedDate = moment(selectedDate).format("YYYY-MM-DD HH:mm");
        let isDateValid = moment(selectedDate).isValid();

        if(isDateValid){
          if(titleRef?.current?.value && urlRef?.current?.value && editorRef.current.getContent() && contentTypeRef?.current?.value){
            const contentType = (contentTypeRef?.current?.value) ? contentTypeRef?.current?.value : '';
            const title = (titleRef?.current?.value) ? titleRef?.current?.value : '';
            const url = (urlRef?.current?.value) ? urlFromString(urlRef?.current?.value) : '';
            const content = (editorRef.current.getContent()) ? editorRef.current.getContent() : '';
            const metaTitle = (metaTitleRef?.current?.value) ? metaTitleRef?.current?.value : '';
            const metaDescription = (metaDescriptionRef?.current?.value) ? metaDescriptionRef?.current?.value : '';
            const createdDate = (createdDateRef?.current?.value) ? createdDateRef?.current?.value : '';
            const category = (categoryRef?.current?.value) ? categoryRef?.current?.value : '';
            const featuredImage = (featuredImageRef?.current?.value) ? featuredImageRef?.current?.value : '';
            const publish_type = type;

            /** Gallery Data */
            let galleryData = [];
            let galleryList = document.querySelectorAll(".galleryImgElement");
            if(isIterable(galleryList)){
              for(let gallery of galleryList){
                let galleryId = gallery?.id;
                let mediaid = gallery.getAttribute("data-mediaid");
                if(galleryId && mediaid){
                  galleryId = galleryId?.replace("gallery_path_", "");
                  if(galleryId){
                    let galleryTitle = document.getElementById("gallery_name_"+galleryId);
                    let galleryAlt = document.getElementById("gallery_alt_"+galleryId);
                    let galleryCaption = document.getElementById("gallery_caption_"+galleryId);
                    let galleryUrl = document.getElementById("gallery_url_"+galleryId);
                    if(galleryTitle){
                      galleryTitle = galleryTitle?.value;
                    }
                    if(galleryAlt){
                      galleryAlt = galleryAlt?.value;
                    }
                    if(galleryCaption){
                      galleryCaption = galleryCaption?.value;
                    }
                    if(galleryUrl){
                      galleryUrl = galleryUrl?.value;
                      if(!galleryUrl){
                        galleryUrl = "";
                      }
                    }
                    galleryData.push({
                      galleryId: mediaid,
                      galleryTitle: galleryTitle,
                      galleryAlt: galleryAlt,
                      galleryCaption: galleryCaption,
                      galleryUrl: galleryUrl
                    })
                  }
                }
              }
            }
            //console.log("galleryData", galleryData);
            /** Gallery Data */

            setBtnDisable(true);
            const response = await axios.post(secure_api('add-new-post'), {
              contentType: contentType,
              title: title,
              url: url,
              content: content,
              meta_title: metaTitle,
              meta_description: metaDescription,
              createdDate: createdDate,
              featuredImage: featuredImage,
              galleryData: galleryData,
              status: publish_type,
              contentId: (id ? id : '')
            });
            setBtnDisable(false);
            console.log("response", response);
            if(response?.data?.type=='success'){
              setSuccessMsg(response?.data);
              if(response?.data?.content?.id){
                urlRef.current.value = response?.data?.content?.url;
                navigate("/edit-post/"+response?.data?.content?.id);
              }
            }
            if(response?.data?.type=='error'){
              setApiError(response?.data?.message);
            }
          }
          else{
            setApiError([
              'Title is required',
              'Url is required',
              'Content is required'
            ])
          }
        }
        else{
          setDateError("Date in invalid");
        }
      }
      catch(e){
        console.log("savePost", e);
        setBtnDisable(false);
      }
    }

    const insertIntoPost=(text)=>{
      if(text){
        editorRef.current.execCommand('mceInsertContent', false, text);
        dispatch(setIsModalOpen(false));
      }
    }

    const insertIntoGallery=(selectedFiles)=>{
      if(isIterable(selectedFiles)){
        setGalleryFiles((prev)=>[...selectedFiles, ...prev]);
        dispatch(setIsModalOpen(false));
      }
    }

    useEffect(()=>{
      //console.log("id", id)
      if(id){
        setBtnDisable(true);
        axios.get(secure_api("get-post/"+id))
        .then(response=>{
          setBtnDisable(false);
          if(response?.data?.content){
            let content = response?.data?.content;
            titleRef.current.value = content?.title;
            urlRef.current.value = content?.url;
            setEditorContent(content?.content);
            metaTitleRef.current.value = content?.meta_title;
            metaDescriptionRef.current.value = content?.meta_description;
            contentTypeRef.current.value = content?.content_type;

            if(content?.media?.length>0){
              setGalleryFiles(content?.media);
            }

            setPostContent(response?.data?.content);
          }
          else{
            setApiError(['Content not found'])
            window.location.href = "/add-new-post";
          }
          console.log("response", response);
        })
        .catch(error=>console.log("get-post error", error));
        //console.log("id", id)
      }
    }, [id]);

  return (
    <>
<MainLayout>
<div className={ "hold-transition sidebar-mini " + (mediamodal?.isOpenModal ? 'modal-open' : '') }>
<div className="wrapper">
  <TopMenu />

  <SiderBar />

  <div className="content-wrapper">

    <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1>{id ? "Edit Post" : "Add New Post"}</h1>
          </div>
          <div className="col-sm-6">
          </div>
        </div>

        {
            (successMsg && urlRef?.current?.value) &&
            <div className="row mb-2">
            <div className="col-sm-12">
            <div className="alert alert-success alert-dismissible">
                Post has been published, view - &nbsp;
                <a target="_blank" href={ backend_url(urlRef.current.value) }>{ backend_url(urlRef.current.value) }</a>
            </div>
            </div>
            </div>
        }

        {
          (apiError) && 
          <div className="row">
            {
              (isIterable(apiError)) 
              ? 
              (
                apiError?.map((data, key)=>{
                  return <div key={key} className="col-sm-12">
                            <ErrorMsg message={data}/>
                        </div>
                })
              )
              : 
              <div className="col-sm-6">
                <ErrorMsg message={apiError}/>
            </div>
            }
          </div>
        }
      </div>
    </section>


    <section className="content">
      <div className="row">
        <div className="col-md-9">
        <div className="card card-default">
          <div className="card-body">

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Title</label>
                  <input type="text" className="form-control" ref={titleRef} onKeyUp={startTyping} onBlur={generateUrl} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Url</label>
                  {/* <input type="text" readOnly={id ? true : false} className="form-control" ref={urlRef} onKeyUp={startTyping}/> */}
                  <input type="text" className="form-control" ref={urlRef} onKeyUp={startTyping}/>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Content</label>
                  <div className='editorWrap'>
                  {/* <div className='col-md-3'>
                        <button type="button" className="btn btn-block btn-default imageUploadBtn" onClick={()=>setIsOpenModal(true)}><i className="fas fa-camera"></i> Add Media</button>
                  </div> */}
                  <Editor
                    tinymceScriptSrc="/js/tinymce/tinymce.min.js"
                    onInit={(evt, editor) => editorRef.current = editor}
                    initialValue={editorContent}
                    init={{
                        promotion:false,
                        branding:false,
                        height: 500,
                        menubar: false,
                        plugins: 'link image code lists wordcount',
                        toolbar: 'styles | bold italic | numlist bullist | alignleft aligncenter alignright | link | code',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        selector:'textarea',
                        content_css:"/tinymce.css"
                    }}
                />
                </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Meta Title</label>
                  <input type="text" className="form-control" ref={metaTitleRef} onKeyUp={startTyping}/>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Meta Description</label>
                  <textarea className="form-control" defaultValue="" ref={metaDescriptionRef} onKeyUp={startTyping}/>
                </div>
              </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                    <div className="card-header">
                        <h3 className="card-title makeMediaGallery"><i className="fas fa-camera"></i> Upload Image Gallery</h3>
                    </div>
                    <div className="card-body row">
                        <div className="col-md-12">
                        <a className="btn btn-app galleryUploadBtn" onClick={()=>dispatch(setIsModalOpen(true))} href={void(0)}>
                            <i className="fas fa-upload"></i> Start upload
                        </a>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            <div className='galleryCount'>Total gallery count- {(galleryFiles?.length>0) ? galleryFiles?.length : '0'}</div>
            {
              (galleryFiles?.length>0) && <GalleryFiles galleryFiles={galleryFiles}/>
            }

          </div>
          <div className="card-footer"></div>
        </div>
        </div>

        <div className="col-md-3">

              <PublishButtons date={date} dateRef={dateRef} month={month} monthRef={monthRef} year={year} yearRef={yearRef} hour={hour} hourRef={hourRef} minute={minute} minRef={minRef} dateError={dateError} id={id} postContent={postContent} btnDisable={btnDisable} savePost={savePost} />

            <div className="card card-default">
              <div className="card-header">
                <h3 className="card-title">Content Type</h3>
              </div>
              <div className="card-body">
                <select className="form-control" ref={contentTypeRef}>
                  <option value="gallery">Gallery</option>
                  {/* <option value="article">Article</option> */}
                </select>
              </div>
            </div>

            {/* <div className="card card-default">
              <div className="card-header">
                <h3 className="card-title">Category</h3>
              </div>
              <div className="card-body">
                <div className="form-check">
                    <input className="form-check-input" type="checkbox"/>
                    <label className="form-check-label">Checkbox</label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox"/>
                    <label className="form-check-label">Checkbox</label>
                </div>
              </div>
            </div> */}

            {/* <div className="card card-default">
              <div className="card-header">
                <h3 className="card-title">Featured Image</h3>
              </div>
              <div className="card-body">
              <p className="text-primary">Set featured image</p>
              <img className="featuredImg" src="https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111-1500x1000.jpg" />
              </div>
            </div> */}

        </div>

        <UploadMediaModal insertIntoPost={insertIntoPost} insertIntoGallery={insertIntoGallery} />

      </div>

    </section>

  </div>

  <Footer />


  <aside className="control-sidebar control-sidebar-dark">

  </aside>

</div>
</div>
</MainLayout>
    </>
  )
}
