import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    siteOptions: null,
    user: null,
}

export const commonSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        addSiteOptions: (state, action)=>{
            state.siteOptions = action.payload
        },
        addUser: (state, action)=>{
            state.user = action.payload
        }
    }
});

export const { addSiteOptions, addUser } = commonSlice.actions;
export default commonSlice.reducer;