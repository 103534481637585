import './styles/all.min.css';
import './styles/adminlte.css';
import './styles/summernote-bs4.min.css';
import './styles/codemirror.css';
import './styles/monokai.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import 'react-loading-skeleton/dist/skeleton.css'
import AddNewPost from './templates/AddNewPost';
import Home from './templates/Home';
import Login from './templates/Login';
import Register from './templates/Register';
import Auth from './middleware/Auth';
import PageNotFound from './templates/PageNotFound';
import WithoutLogin from './middleware/WithoutLogin';
import ViewPosts from './templates/ViewPosts';
import AddNewPage from './templates/AddNewPage';
import ViewPages from './templates/ViewPages';
import AddMenu from './templates/AddMenu';
import Settings from './templates/Settings';
import Redirection from './templates/Redirection';

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Auth><ViewPosts /></Auth>
    },
    {
      path: "/login",
      element: <WithoutLogin><Login /></WithoutLogin>
    },
    // {
    //   path: "/register",
    //   element: <WithoutLogin><Register /></WithoutLogin>
    // },
    {
      path: "/all-posts",
      element: <Auth><ViewPosts /></Auth>
    },
    {
      path: "/add-new-post",
      element: <Auth><AddNewPost /></Auth>
    },
    {
      path: "/edit-post/:id",
      element: <Auth><AddNewPost /></Auth>
    },
    {
      path: "/all-pages",
      element: <Auth><ViewPages /></Auth>
    },
    {
      path: "/add-new-page",
      element: <Auth><AddNewPage /></Auth>
    },
    {
      path: "/edit-page/:id",
      element: <Auth><AddNewPage /></Auth>
    },
    {
      path: "/add-menu",
      element: <Auth><AddMenu /></Auth>
    },
    {
      path: "/settings",
      element: <Auth><Settings /></Auth>
    },
    {
      path: "/redirection",
      element: <Auth><Redirection /></Auth>
    },
    {
      path: "*",
      element: <PageNotFound />
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
