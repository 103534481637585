import React from 'react'
import { useSelector } from 'react-redux';

export default function Footer() {

    const store = useSelector(state=> state?.common);

  return (
    <>
    <footer className="main-footer">
        <div className="float-right d-none d-sm-block">
        </div>
        <strong>Copyright &copy; 2014-2030 <a href="/">{(store?.siteOptions?.site_name ? store?.siteOptions?.site_name : "Admin Panel")}</a>.</strong> All rights reserved.
    </footer>
    </>
  )
}
